// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);

// Navtoggler

window.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {
    document.body.classList.toggle('body--activenav');
  })
})

// Offcanvas

window.addEventListener('DOMContentLoaded', () => {
  for (let parentOffcanvasItem of document.querySelectorAll('.headerbar__navigation li.parent')) {
    parentOffcanvasItem.querySelector('a, span').addEventListener('click', (e) => {
      if (window.innerWidth > 1200) {
        return
      }

      e.preventDefault();
      parentOffcanvasItem.classList.toggle('opened');
    })
  }
})

// Sidebarnav

window.addEventListener('DOMContentLoaded', () => {
  for (let parentOffcanvasItem of document.querySelectorAll('.sidebarnav li.parent')) {
    parentOffcanvasItem.querySelector('a, span').addEventListener('click', (e) => {
      e.preventDefault();
      parentOffcanvasItem.classList.toggle('opened');
    })
  }
})

// Shrink Headerbar on Scroll

document.addEventListener('scroll', () => {
  if(window.scrollY > 0) {
    document.body.classList.add('body--scrolled')
  } else {
    document.body.classList.remove('body--scrolled')
  }
})